import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import Image from "../../components/Image"
import SkuTile from "../../components/SkuTile"

import styles from "./pt.module.scss"

const PT = ({ data, location }) => {
  const {
    nodes
  } = data.allProductStyle

  const [allSkus, setAllSkus] = useState([])

  useEffect(() => {
    let reorderedNodes = [
      "lure-90-nappa-sandals",
      "desire-65-nappa-pumps-1",
      "solitude-65-nappa-laminata-sandals-1",
      "absolute-85-nappa-sandals",
      "absolute-40-nappa-laminata-sandals-1",
      "solar-85-nappa-laminata-sandals",
      "solar-65-nappa-laminata-sandals-1",
      "luster-40-nappa-laminata-sandals-1",
      "reverse-wedge-90-nappa-sandals",
      "reverse-sandal-75-nappa-sandals",
      "flatline-10-nappa-laminata-flats",
      "solstice-65-suede-sandals",
      "solstice-10-nappa-laminata-flats",
    ].filter(handle => nodes.find(style => style.handle === handle))
      .map(handle => nodes.find(style => style.handle === handle))

    let updatedSkus = []
    reorderedNodes.forEach(style => {
      style.skus.forEach(sku => {
        sku.handle = style.handle
        if (sku.images.plp2.length > 0)
          updatedSkus.push(sku)
      })
    })
    setAllSkus(updatedSkus)
  }, [nodes])

  return (
    <>
      <SEO
        title="Pillow Top"
        description="Introducing the Pillow Top Collection, handmade with 8mm of high-density Memory Foam cushion for unparalleled comfort."
        url={location.pathname}
      />

      <section className={styles.moduleImage6}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Pillow_Top_Image_1_Desktop_2000x.jpg?v=1707271104",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Pillow_Top_Image_1_Desktop_850x.jpg?v=1707271104",
          }}
          altText="Pillow Top Hero Image"
        />
      </section>

      <section className={styles.moduleText1}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          Pillow Top
        </Heading>
        <p className={styles.text}>
          Introducing the Pillow Top Collection, handmade with 8mm of high-density Memory Foam cushion for unparalleled comfort.
        </p>
        <p className={styles.text}>
          Inspired by the timeless elegance and comfort of her Eames Soft Pad Chair, Tamara worked with the design team in L.A. and Italian artisans to craft first-of-their-kind shoes. Made with dual layer cushioning, they soften and mold to your foot in response to body heat. As a woman designing shoes for other women, Tamara knows what we want. And guess what? Still beautiful.
        </p>
      </section>

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "lure-90-nappa-sandals",
          "desire-65-nappa-pumps-1",
          "absolute-85-nappa-sandals",
          "absolute-40-nappa-laminata-sandals-1",
        ].includes(sku.handle))
          .map((sku, i) => (
          <SkuTile
            key={i}
            index={i}
            style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
            sku={sku}
            customStyles={styles}
            origin={`collection-pt`}
          />
        ))}
      </ul>

      <section className={styles.moduleText1}>
        <Heading
          tag="h1"
          level="1"
          className={styles.heading}
        >
          First-Of-Its-Kind Luxury Cushioning
        </Heading>
        <p className={styles.text}>
          8mm of high-density Memory Foam Dual layer cushioning for unparalleled comfort. Highly energy-absorbent and soft. Softens in response to body heat, molding to your foot.
        </p>
      </section>

      <section className={styles.moduleImage2}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Pillow_Top_Image_2_Desktop_2000x.jpg?v=1707271105",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Pillow_Top_Image_2_Mobile_850x.jpg?v=1707271104",
          }}
          altText="Pillow Top Image"
        />
      </section>

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "solitude-65-nappa-laminata-sandals-1",
          "solar-85-nappa-laminata-sandals",
          "solar-65-nappa-laminata-sandals-1",
          "luster-40-nappa-laminata-sandals-1",
          "flatline-10-nappa-laminata-flats",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-pt`}
            />
          ))}
      </ul>

      <section className={styles.moduleImage3}>
        <Image
          image={{
            desktopImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Pillow_Top_Image_3_Desktop_2000x.jpg?v=1707271104",
            mobileImage: "https://cdn.shopify.com/s/files/1/1103/4464/files/Pillow_Top_Image_3_Mobile_850x.jpg?v=1707271104",
          }}
          altText="Pillow Top Image"
        />
      </section>

      <ul className={styles.grid}>
        {allSkus.filter(sku => [
          "reverse-wedge-90-nappa-sandals",
          "reverse-sandal-75-nappa-sandals",
          "solstice-65-suede-sandals",
          "solstice-10-nappa-laminata-flats",
        ].includes(sku.handle))
          .map((sku, i) => (
            <SkuTile
              key={i}
              index={i}
              style={nodes.find(style => style.skus.find(s => s.id === sku.id))}
              sku={sku}
              customStyles={styles}
              origin={`collection-pt`}
            />
          ))}
      </ul>
    </>
  )
}

export default PT

export const query = graphql`
    query PTQuery {
        allProductStyle(filter: {handle: {in: [
          "lure-90-nappa-sandals",
          "desire-65-nappa-pumps-1",
          "solitude-65-nappa-laminata-sandals-1",
          "absolute-85-nappa-sandals",
          "absolute-40-nappa-laminata-sandals-1",
          "solar-85-nappa-laminata-sandals",
          "solar-65-nappa-laminata-sandals-1",
          "luster-40-nappa-laminata-sandals-1",
          "reverse-wedge-90-nappa-sandals",
          "reverse-sandal-75-nappa-sandals",
          "flatline-10-nappa-laminata-flats",
          "solstice-65-suede-sandals",
          "solstice-10-nappa-laminata-flats",
        ]}}) {
            nodes {
                ...PLPStyleFragment
                handle
            }
        }
    }
`
